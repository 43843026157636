import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import "assets/css/demo.css";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const hist = createBrowserHistory();

function App() {
  return (
      <Router history={hist}>
        <Switch>
          <Route
            path="/admin"
            render={props => {
              return <AdminLayout {...props} />;
            }}
          />
          <Route
            path="/auth"
            render={props => {
              return <AuthLayout {...props} />;
            }}
          />
          <Redirect to="/admin/dashboard" />
        </Switch>
      </Router>
  );
}

export default withAuthenticator(App);
